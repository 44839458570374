import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import CustomFetch from '../components/fetch';
import CustomMessage from '../components/message';
import axios from 'axios';
import sal from 'sal.js';
import { Stop } from 'akar-icons';

const NewdocumentPage = () => {
    const [data, setData] = useState({
        subcontractor_document_category_id: '',
        subcontractor_id: 0,
        label: '',
        file: '',
    });
    const [dataClass, setDataClass] = useState({
        subcontractor_document_category_id: 'formitem fw',
        label: 'formitem fw',
        file: 'formitem fw',
    });
    const [showError, setShowError] = useState(false);
    const [subcontractorDocumentCategory, setSubcontractorDocumentCategory] =
        useState([]);
    const [messageSetting, setMessageSetting] = useState({
        showMessage: false,
        messageClass: 'dialog dialog--error',
        messageContent: '',
    });

    const handleChange = (e) => {
        const { name, type } = e.target;
        const value = type === 'file' ? e.target.files[0] : e.target.value;

        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let _error = false;
        if (
            data.subcontractor_document_category_id === '' ||
            data.label === ''
        ) {
            _error = true;
        }

        if (data.subcontractor_document_category_id === '') {
            setDataClass((prevState) => ({
                ...prevState,
                subcontractor_document_category_id: 'formitem fw inputerror',
            }));
        }

        if (data.label === '') {
            setDataClass((prevState) => ({
                ...prevState,
                label: 'formitem fw inputerror',
            }));
        }

        if (data.file === '') {
            setDataClass((prevState) => ({
                ...prevState,
                file: 'formitem fw inputerror',
            }));
        }

        if (!_error) {
            const formData = new FormData();
            formData.append(
                'subcontractor_document_category_id',
                data.subcontractor_document_category_id
            );
            formData.append('subcontractor_id', data.subcontractor_id);
            formData.append('label', data.label);
            formData.append('file', data.file);

            const token = localStorage.getItem('dpTicketToken'); // Retrieve the token from localStorage
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                },
            };

            axios
                .post(
                    `${process.env.GATSBY_API_URL}subcontractorDocuments`,
                    formData,
                    config
                )
                .then((response) => {
                    console.log('Success:', response);
                    // Handle success, such as navigating to another page
                    navigate('/document');
                })
                .catch((error) => {
                    console.error('Error:', error);
                    // Handle error
                    setMessageSetting((prevState) => ({
                        ...prevState,
                        showMessage: true,
                        messageClass: 'dialog dialog--error',
                        messageContent: 'Submission failed!',
                    }));
                });
        } else {
            setShowError(true);
        }
    };

    useEffect(() => {
        CustomFetch(
            'subcontractorDocumentCategories/dropdown',
            'POST',
            {},
            function (result) {
                setSubcontractorDocumentCategory(result.data);
            },
            function (error) {
                setMessageSetting((prevState) => ({
                    ...prevState,
                    showMessage: true,
                    messageClass: 'dialog dialog--error',
                    messageContent: error,
                }));
            }
        );

        CustomFetch(
            'checkAuthStatus',
            'POST',
            {},
            function (result) {
                if (result.user.subcontractor) {
                    setData((prevState) => ({
                        ...prevState,
                        subcontractor_id: result.user.subcontractor.id,
                    }));
                }
            },
            function (error) {
                console.info(error);
            }
        );
    }, []);

    useEffect(() => {
        setTimeout(() => {
            sal();
        }, 100);
    }, [dataClass]);

    return (
        <Layout title="New Document">
            <Seo
                title="New Document"
                description="Dawn Projects Ticket System"
            />
            <CustomMessage data={messageSetting} />
            <form onSubmit={handleSubmit}>
                <div className="wrap">
                    <div className="ticketform">
                        {showError === true ? (
                            <div className="formitem ticketerror">
                                <Stop size={24} />
                                <span>
                                    Error! Please check the required fields.
                                </span>
                            </div>
                        ) : null}
                        <div
                            className={
                                dataClass.subcontractor_document_category_id
                            }
                            data-sal="slide-down"
                            data-sal-duration="500"
                            data-sal-easing="ease-in-out-quart"
                        >
                            <div className="select">
                                <select
                                    name="subcontractor_document_category_id"
                                    onChange={handleChange}
                                    value={
                                        data.subcontractor_document_category_id
                                    }
                                >
                                    <option defaultValue hidden>
                                        Category
                                    </option>
                                    {subcontractorDocumentCategory.length > 0
                                        ? subcontractorDocumentCategory.map(
                                              (a) => (
                                                  <option
                                                      key={`category-${a.id}`}
                                                      value={a.id}
                                                  >
                                                      {a.label}
                                                  </option>
                                              )
                                          )
                                        : null}
                                </select>
                                <small>Required</small>
                            </div>
                        </div>
                        <div
                            className={dataClass.label}
                            data-sal="slide-down"
                            data-sal-duration="500"
                            data-sal-easing="ease-in-out-quart"
                        >
                            <label className="fi__label">
                                <input
                                    name="label"
                                    onChange={handleChange}
                                    value={data.label}
                                    type="text"
                                    placeholder=" "
                                />
                                <span className="fi__span">
                                    Label <small>Required</small>
                                </span>
                            </label>
                        </div>
                        <div
                            className={dataClass.file}
                            data-sal="slide-down"
                            data-sal-duration="500"
                            data-sal-easing="ease-in-out-quart"
                        >
                            <label className="fi__label">
                                <input
                                    name="file"
                                    onChange={handleChange}
                                    type="file"
                                    placeholder=" "
                                />
                                <span className="fi__span">
                                    File
                                    <small>Required</small>
                                </span>
                            </label>
                        </div>
                        <div
                            className="formitem fw"
                            data-sal="slide-down"
                            data-sal-duration="500"
                            data-sal-easing="ease-in-out-quart"
                        >
                            <button type="submit" className="btn">
                                Submit Ticket
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Layout>
    );
};

export default NewdocumentPage;
